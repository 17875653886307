<template>
  <div>
    <div ref="editor"></div>
  </div>
</template>

<script>
import E from "wangeditor";
export default {
  name: "editor",
  data() {
    return {
      editor: null,
    };
  },
  computed: {
    // 上传时的请求头
    token() {
      const token = JSON.parse(localStorage.getItem("userInfo")).token;
      return token;
    },
  },
  methods: {
    init(value) {
      let editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head",
        "bold", // 粗体
        "fontSize", // 字号
        "fontName", // 字体
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "indent", // 缩进
        "lineHeight", // 行高
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "link", // 插入链接
        "list", // 列表
        // "todo", //待办事项
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        // "video", //视频
        "table", // 表格
        // "code", //代码
        "splitLine",
        "undo", // 撤销
        "redo", // 恢复
      ];
      editor.config.onchange = (html) => {
        this.$emit("input", html);
      };
      editor.config.uploadImgServer =
        "https://www.jsfgx.online/newcar/newcar/common/upload";
      editor.config.uploadImgMaxSize = 10 * 1024 * 1024; // 将图片大小限制为 10M
      editor.config.uploadFileName = "file"; //后端接受上传文件的参数名
      editor.config.uploadImgMaxLength = 1; // 限制一次最多上传 1 张图片
      editor.config.showLinkImg = false; //隐藏网络图片上传
      editor.config.uploadImgHeaders = {
        Token: this.token,
      };
      editor.config.uploadImgHooks = {
        fail: (xhr, editor, result) => {
          // 插入图片失败回调
          console.log(xhr, editor, result);
        },
        success: (xhr, editor, result) => {
          // 图片上传成功回调
          console.log(xhr, editor, result);
        },
        timeout: (xhr, editor) => {
          // 网络超时的回调
          console.log("网络超时", xhr, editor);
        },
        error: (xhr, editor) => {
          // 图片上传错误的回调
          console.log("上传错误", xhr, editor);
        },
        //回显
        customInsert: (insertImg, result) => {
          let url =
            "https://www.jsfgx.online/newcar/newcar/common/pic/" + result.data;
          insertImg(url);
        },
      };
      editor.create();
      editor.txt.html(value);
      this.editor = editor;
    },
    destroy() {
      if (this.editor) {
        this.editor.destroy();
        this.editor = null;
      }
    },
  },
};
</script>

<style scoped></style>
