<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="36%"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="80px"
    >
      <el-form-item label="所属代理商" prop="cid">
        <el-select
          style="width: 500px"
          v-model="companyName"
          clearable
          :placeholder="!dataForm.id ? '请输入关键词搜索' : companyName"
          @change="changeCompany"
          filterable
          @focus="companySelectFocus"
        >
          <el-option
            v-for="item in companyOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属商家" prop="bid">
        <el-select
          style="width: 500px"
          v-model="businessName"
          clearable
          :placeholder="!dataForm.id ? '请输入关键词搜索' : businessName"
          @change="changeBusiness"
          filterable
          @focus="businessSelectFocus"
        >
          <el-option
            v-for="item in businessOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品名" prop="name">
        <el-input
          v-model="dataForm.name"
          placeholder="请输入商品名"
          style="width: 500px"
        ></el-input>
      </el-form-item>
      <el-form-item label="折前价格" prop="priceFront">
        <el-input
          oninput="value=value.replace(/[^0-9.]/g,'')"
          v-model="dataForm.priceFront"
          style="width: 500px"
          placeholder="请输入折前价格，最多两位小数"
        ></el-input>
      </el-form-item>
      <el-form-item label="折后价格" prop="price">
        <el-input
          oninput="value=value.replace(/[^0-9.]/g,'')"
          v-model="dataForm.price"
          style="width: 500px"
          placeholder="请输入折后价格，最多两位小数"
        ></el-input>
      </el-form-item>
      <el-form-item label="商品图片" prop="picture">
        <el-upload
          class="upload-demo"
          action="https://www.jsfgx.online/newcar/newcar/common/upload"
          :on-success="uploadSuccess"
          :on-remove="removePic"
          :file-list="fileList"
          :headers="uploadHeaders"
          list-type="picture"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="商品介绍" prop="introduce">
        <!-- <el-input
          v-model="dataForm.introduce"
          placeholder="商品介绍"
          style="width: 500px"
        ></el-input> -->
        <div>
          <editor ref="editor" v-model="dataForm.introduce"></editor>
        </div>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select
          style="width: 500px"
          v-model="dataForm.status"
          clearable
          :placeholder="statusName ? statusName : '请选择商品状态'"
        >
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品类型" prop="type">
        <el-select
          style="width: 500px"
          v-model="dataForm.type"
          clearable
          @change="typeSelect"
          :placeholder="typeName ? typeName : '请选择商品类型'"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="品牌" prop="brand" v-if="showBrand">
        <el-select
          style="width: 500px"
          v-model="dataForm.brand"
          clearable
          :placeholder="dataForm.brand ? dataForm.brand : '请选择品牌'"
          filterable
          @focus="brandSelectFocus"
        >
          <el-option
            v-for="item in brandOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="库存" prop="stock">
        <el-input
          v-model="dataForm.stock"
          placeholder="库存"
          style="width: 500px"
        ></el-input>
      </el-form-item>
      <el-form-item label="是否置顶" prop="isTop">
        <el-switch
          v-model="topValue"
          active-text="是"
          inactive-text="否"
          @change="topChange"
        >
        </el-switch>
      </el-form-item>
      <el-form-item v-if="showWeixiu" label="维修商品" prop="isWeixiu">
        <el-switch
          v-model="weixiuValue"
          active-text="是"
          inactive-text="否"
          @change="weixiuChange"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="标签" prop="tag">
        <el-tag
          :key="tag"
          v-for="tag in dynamicTags"
          closable
          :disable-transitions="false"
          @close="handleClose(tag)"
        >
          {{ tag }}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        >
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInput"
          >+新增标签
        </el-button>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  getCompanyList,
  getProductInfo,
  getBusinessList,
  updateProduct,
  saveProduct,
} from "../../api/api";

import Editor from "../../src/components/editor";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      visible: false,
      // 代理商名回显
      companyName: "",
      // 商家名回显
      businessName: "",
      //  代理商选择器列表
      companyOptions: [],
      //  代理商选择器列表
      businessOptions: [],
      // 图片列表
      fileList: [],
      // 类型选择项
      typeOptions: [
        {
          value: 0,
          label: "新车",
        },
        {
          value: 1,
          label: "二手车",
        },
        {
          value: 2,
          label: "洗养产品",
        },
        {
          value: 3,
          label: "加油站产品",
        },
        {
          value: 4,
          label: "检测站项目",
        },
        {
          value: 5,
          label: "维修站项目",
        },
      ],
      // 类型回显
      typeName: "",
      // 状态选择项
      statusOptions: [
        {
          value: 0,
          label: "未上架",
        },
        {
          value: 1,
          label: "已上架",
        },
        {
          value: 2,
          label: "已下架",
        },
      ],
      // 状态回显
      statusName: "",
      topValue: false, // 置顶开关绑定值
      weixiuValue: false, // 维修商品开关绑定值
      dynamicTags: [], // 标签列表
      inputVisible: false,
      showWeixiu: false, // 显示维修商品开关
      inputValue: "",
      // 品牌选择列表
      brandOptions: [],
      showBrand: false, // 是否显示品牌选择框
      dataForm: {
        id: 0,
        cid: "",
        bid: "",
        name: "",
        priceFront: "",
        price: "",
        picture: "",
        introduce: "",
        status: "",
        type: "",
        sale: "",
        stock: "",
        isTop: 0,
        tag: "",
        createDate: "",
        updateDate: "",
        isDeleted: "",
        brand: "",
        isWeixiu: "",
      },
      dataRule: {
        cid: [{ required: true, message: "代理商不能为空", trigger: "blur" }],
        bid: [{ required: true, message: "商家不能为空", trigger: "blur" }],
        name: [{ required: true, message: "商品名不能为空", trigger: "blur" }],
        priceFront: [
          { required: true, message: "折前价格不能为空", trigger: "blur" },
        ],
        price: [
          { required: true, message: "折前价格不能为空", trigger: "blur" },
        ],
        introduce: [
          { required: true, message: "商品介绍不能为空", trigger: "blur" },
        ],
        status: [
          {
            required: true,
            message: "状态不能为空",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "商品类型不能为空",
            trigger: "blur",
          },
        ],
        stock: [{ required: true, message: "库存不能为空", trigger: "blur" }],
        isTop: [
          {
            required: true,
            message: "是否置顶不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    // 上传时的请求头
    uploadHeaders() {
      const token = JSON.parse(localStorage.getItem("userInfo")).token;
      return { Token: token };
    },
  },
  methods: {
    // 类型选择框选择事件
    typeSelect(item) {
      if (item == 0 || item == 1) {
        this.showBrand = true;
      } else {
        this.showBrand = false;
      }
    },
    // 品牌选择框获得焦点事件
    brandSelectFocus() {
      if (this.brandOptions.length < 1) {
        const brands = require("../../public/carBrand.json");
        for (let item of brands) {
          let nav = {};
          nav.value = item.title;
          nav.name = item.title;
          nav.disabled = true;
          this.brandOptions.push(nav);
          for (let brand of item.lists) {
            this.brandOptions.push({
              value: brand,
              name: brand,
              disabled: false,
            });
          }
        }
      }
    },
    // 关闭标签时触发的事件
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    // 确认添加标签
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    // 新增标签
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    // 是否置顶开关回调
    topChange(item) {
      if (item) {
        this.dataForm.isTop = 1;
      } else {
        this.dataForm.isTop = 0;
      }
    },
    // 是否维修产品开关回调
    weixiuChange(item) {
      if (item) {
        this.dataForm.isWeixiu = 1;
      } else {
        this.dataForm.isWeixiu = 0;
      }
    },
    // 选择代理商的事件
    changeCompany(value) {
      this.dataForm.bid = "";
      this.businessName = "";
      this.dataForm.cid = value;
    },
    // 代理商选择器获得焦点事件
    companySelectFocus() {
      if (this.companyOptions.length > 0) {
        return;
      }
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo.type == 1) {
        return;
      }

      this.dataListLoading = true;
      getCompanyList({ limit: 9999 }).then((data) => {
        if (data && data.code === 0) {
          let resultList = data.data.list;
          if (resultList.length > 0) {
            this.companyOptions = [];
            for (let item of resultList) {
              this.companyOptions.push({
                value: item.id,
                label: item.name,
              });
            }
          }
        }
        this.dataListLoading = false;
      });
    },
    // 选择商家的事件
    changeBusiness(value) {
      this.dataForm.bid = value;
    },
    // 商家选择器获得焦点事件
    businessSelectFocus() {
      if (this.dataForm.cid == "" || this.dataForm.cid == null) {
        this.$message.error("请先选择代理商");
        return;
      }
      this.dataListLoading = true;
      getBusinessList({ limit: 9999, cid: this.dataForm.cid }).then((data) => {
        if (data && data.code === 0) {
          let resultList = data.data.list;
          if (resultList.length > 0) {
            this.businessOptions = [];
            for (let item of resultList) {
              this.businessOptions.push({
                value: item.id,
                label: item.name,
              });
            }
          }
        }
        this.dataListLoading = false;
      });
    },
    // 上传图片成功的事件
    uploadSuccess(response) {
      if (response.code === 0) {
        this.fileList.push({
          name: response.data,
          url: "https://www.jsfgx.online/newcar/newcar/common/pic/" + response.data,
        });
      }
    },
    // 删除图片事件
    removePic(file, fileList) {
      if (file) {
        this.fileList = fileList;
      }
    },
    init(id) {
      this.dataForm.id = id || 0;
      this.visible = true;
      this.fileList = [];
      this.companyName = "";
      this.businessName = "";
      this.dynamicTags = [];
      this.topValue = false;

      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo.type == 0) {
        this.showWeixiu = true;
      }

      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (userInfo.type == 1) {
          this.dataForm.cid = userInfo.company.id;
          this.companyOptions.push({
            value: this.dataForm.cid,
            label: userInfo.company.name,
          });
          this.companyName = userInfo.company.name;
        }
        if (this.dataForm.id) {
          getProductInfo({ id: this.dataForm.id }).then((data) => {
            if (data && data.code === 0) {
              this.dataForm = data.data;

              if (this.dataForm.type === 0) {
                this.typeName = "新车";
              } else if (this.dataForm.type === 1) {
                this.typeName = "二手车";
              } else if (this.dataForm.type === 2) {
                this.typeName = "洗养产品";
              } else if (this.dataForm.type === 3) {
                this.typeName = "加油站产品";
              } else if (this.dataForm.type === 4) {
                this.typeName = "检测站项目";
              } else if (this.dataForm.type === 5) {
                this.typeName = "维修站项目";
              }

              if (this.dataForm.status === 0) {
                this.statusName = "未上架";
              } else if (this.dataForm.status === 1) {
                this.statusName = "已上架";
              } else if (this.dataForm.status === 2) {
                this.statusName = "已下架";
              }

              if (this.dataForm.isTop == 0) {
                this.topValue = false;
              } else if (this.dataForm.isTop == 1) {
                this.topValue = true;
              }

              this.companyName = data.data.companyName;
              this.businessName = data.data.businessName;
              this.dataForm.price = this.dataForm.price / 100;
              this.dataForm.priceFront = this.dataForm.priceFront / 100;

              for (let tag of data.data.tag.split("_")) {
                if (tag) {
                  this.dynamicTags.push(tag);
                }
              }

              for (let pic of data.data.picture.split("_")) {
                if (pic) {
                  let picture = {
                    name: pic,
                    url: "https://www.jsfgx.online/newcar/newcar/common/pic/" + pic,
                  };
                  this.fileList.push(picture);
                }
              }

              this.$refs.editor.destroy();
              this.$refs.editor.init(this.dataForm.introduce);
            }
          });
        }
        this.$refs.editor.destroy();
        this.$refs.editor.init(this.dataForm.introduce);
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let requestDate = this.dataForm;
          // 价格
          requestDate.price = requestDate.price * 100;
          requestDate.priceFront = requestDate.priceFront * 100;
          // 图片
          let pics = "";
          for (let pic of this.fileList) {
            pics =
              pics +
              pic.url.substring(pic.url.lastIndexOf("/") + 1, pic.url.length) +
              "_";
          }
          pics = pics.substring(0, pics.length - 1);
          requestDate.picture = pics;
          // 标签
          let tags = "";
          for (let tag of this.dynamicTags) {
            tags = tags + tag + "_";
          }
          tags = tags.substring(0, tags.length - 1);
          requestDate.tag = tags;
          // 品牌
          if (!this.showBrand) {
            requestDate.brand = "";
          }
          if (this.dataForm.id) {
            // 修改
            updateProduct(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "修改操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          } else {
            // 新增
            requestDate.id = "";
            saveProduct(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
