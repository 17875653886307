<template>
  <div class="mod-config">
    <el-form
      :inline="true"
      :model="dataForm"
      @keyup.enter.native="getDataList()"
    >
      <el-form-item>
        <el-input
          v-model="dataForm.keyword"
          placeholder="请输入关键字"
          clearable
        ></el-input>
      </el-form-item>
      <el-select
        v-model="dataForm.cid"
        clearable
        :placeholder="companyName ? companyName : '按代理商筛选'"
        filterable
        @focus="companySelectFocus"
        style="margin-right: 10px"
        @change="changeCompany"
      >
        <el-option
          v-for="item in companyOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="dataForm.bid"
        clearable
        placeholder="按商家筛选"
        filterable
        @focus="businessSelectFocus"
        style="margin-right: 10px"
      >
        <el-option
          v-for="item in businessOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="dataForm.type"
        clearable
        placeholder="按类型筛选"
        style="margin-right: 10px"
      >
        <el-option
          v-for="item in typeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="dataForm.status"
        clearable
        placeholder="按状态筛选"
        style="margin-right: 10px"
      >
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        prop="id"
        header-align="center"
        align="center"
        label="编号"
      >
      </el-table-column>
      <el-table-column
        prop="companyName"
        header-align="center"
        align="center"
        label="所属代理商"
      >
      </el-table-column>
      <el-table-column
        prop="businessName"
        header-align="center"
        align="center"
        label="所属商家"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        header-align="center"
        align="center"
        label="商品名"
      >
      </el-table-column>
      <el-table-column
        prop="price"
        header-align="center"
        align="center"
        label="价格"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.price / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="introduce"
        header-align="center"
        align="center"
        label="商品介绍"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="status"
        header-align="center"
        align="center"
        label="状态"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.status == 0">未上架</span>
          <span v-if="scope.row.status == 1">已上架</span>
          <span v-if="scope.row.status == 2">已下架</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="type"
        header-align="center"
        align="center"
        label="商品类型"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.type == 0">新车</span>
          <span v-if="scope.row.type == 1">二手车</span>
          <span v-if="scope.row.type == 2">洗护产品</span>
          <span v-if="scope.row.type == 3">加油站产品</span>
          <span v-if="scope.row.type == 4">车检项目</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="sale"
        header-align="center"
        align="center"
        label="销量"
      >
      </el-table-column>
      <el-table-column
        prop="stock"
        header-align="center"
        align="center"
        label="库存"
      >
      </el-table-column>
      <el-table-column
        prop="isTop"
        header-align="center"
        align="center"
        label="置顶"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.isTop == 0">否</span>
          <span v-if="scope.row.isTop == 1">是</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="isWeixiu"
        header-align="center"
        align="center"
        label="车检维修商品"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.isWeixiu == 0 || !scope.row.isWeixiu">否</span>
          <span v-if="scope.row.isWeixiu == 1">是</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="tag"
        header-align="center"
        align="center"
        label="标签"
        :show-overflow-tooltip="true"
      >
      </el-table-column>

      <el-table-column
        prop="brand"
        header-align="center"
        align="center"
        label="品牌"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id)"
            >修改</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center; margin-top: 10px"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from "./product-add-or-update";
import {
  deleteProductById,
  getProductList,
  getCompanyList,
  getBusinessList,
} from "../../api/api";
export default {
  data() {
    return {
      dataForm: {
        keyword: "",
        status: "",
        bid: "",
        cid: "",
        type: "",
      },
      //  代理商选择器列表
      companyOptions: [],
      // 代理商选择器默认展示
      companyName: "",
      // 商家选择器列表
      businessOptions: [],
      // 状态选择器列表
      statusOptions: [
        {
          value: 0,
          label: "未上架",
        },
        {
          value: 1,
          label: "已上架",
        },
        {
          value: 2,
          label: "已下架",
        },
      ],
      // 类型选择器列表
      typeOptions: [
        {
          value: 0,
          label: "新车",
        },
        {
          value: 1,
          label: "二手车",
        },
        {
          value: 2,
          label: "洗养产品",
        },
        {
          value: 3,
          label: "加油产品",
        },
        {
          value: 4,
          label: "检测站项目",
        },
        {
          value: 5,
          label: "维修项目",
        },
      ],
      // 数据列表
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  activated() {
    this.getDataList();
  },
  created() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo.cid) {
      this.dataForm.cid = userInfo.cid;
      this.companyOptions.push({
        value: this.dataForm.cid,
        label: userInfo.company.name,
      });
      this.companyName = userInfo.company.name;
    }
    this.getDataList();
  },
  methods: {
    // 选择代理商的事件
    changeCompany(value) {
      this.dataForm.bid = "";
      this.businessName = "";
      this.dataForm.cid = value;
    },
    // 代理商选择框获得焦点事件
    companySelectFocus() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo.cid) {
        return;
      }

      this.dataListLoading = true;
      getCompanyList({ limit: 9999 }).then((data) => {
        if (data && data.code === 0) {
          let resultList = data.data.list;
          this.companyOptions = [];
          if (resultList.length > 0) {
            for (let item of resultList) {
              this.companyOptions.push({
                value: item.id,
                label: item.name,
              });
            }
          }
        }
        this.dataListLoading = false;
      });
    },
    // 商家选择框获得焦点事件
    businessSelectFocus() {
      if (this.dataForm.cid == "" || this.dataForm.cid == null) {
        this.$message.error("请先选择代理商");
        return;
      }
      this.dataListLoading = true;
      getBusinessList({ limit: 99999, cid: this.dataForm.cid }).then((data) => {
        if (data && data.code === 0) {
          let resultList = data.data.list;
          this.businessOptions = [];
          if (resultList.length > 0) {
            for (let item of resultList) {
              this.businessOptions.push({
                value: item.id,
                label: item.name,
              });
            }
          }
        }
        this.dataListLoading = false;
      });
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.dataForm.page = this.pageIndex;
      this.dataForm.limit = this.pageSize;
      getProductList(this.dataForm).then((data) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list;
          this.totalPage = data.data.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`确定要进行删除吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteProductById({
          id,
        }).then((data) => {
          if (data && data.code === 0) {
            this.$message({
              message: data.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              },
            });
          } else {
            this.$message.error(data.msg);
          }
        });
      });
    },
  },
};
</script>
